import { WHOLESALE_BOOKER_ROUTE, WHOLESALE_ROUTE } from 'constants/navigation'
import { WholesalerMenuOption } from 'containers/Wholesale/AllWholesaleProducts'
import { checkEnvironment } from 'helper/environment'

export const getWholesalerById = (wholesalerId: string): WholesalerMenuOption => {
  return ALL_WHOLESALERS.find(x => x.id == wholesalerId);
}

const ALL_WHOLESALERS: WholesalerMenuOption[] = checkEnvironment("stable") ? [
  {
    id: "BOOKER",
    name: "Booker",
    href: WHOLESALE_BOOKER_ROUTE,
    customerId: "123456789",
    signUpHref: "https://www.booker.co.uk/"
  },
  {
    id: "NISA",
    name: "Nisa",
    href: WHOLESALE_ROUTE + "/nisa",
    customerId: null,
    signUpHref: "https://www.nisalocally.co.uk/"
  },
  {
    id: "BESTWAY",
    name: "Bestway",
    href: WHOLESALE_ROUTE + "/bestway",
    customerId: null,
    signUpHref: "https://www.bestwaygroup.co.uk/"
  }
] : [
  {
    id: "BOOKER",
    name: "Booker",
    href: WHOLESALE_ROUTE + '/booker',
    customerId: "123456789",
    signUpHref: "https://www.booker.co.uk/"
  }
];

export default ALL_WHOLESALERS

const WHOLESALER_PRODUCT_ID_LABEL = {
  "BOOKER": "MIDAS",
}
export const getWholesalerProductIdLabel = (k: string) => {
  return WHOLESALER_PRODUCT_ID_LABEL[k] || "Ref";
}