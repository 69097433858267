import { getWholesalerById } from 'data/wholesalers'
import React, { useReducer, useContext, createContext } from 'react';
import { reducer, cartItemsTotalPrice } from './wholesaleCart.reducer';
import { useStorage } from 'helper/useStorage';
import { WholesaleProduct } from 'models/Wholesale';
const WholesaleCartContext = createContext({} as any);
import arrayUniq from 'array-uniq'

export type WholesaleCartProps = {
  provider: string;
  items: WholesaleProduct[];
}

const INITIAL_STATE: WholesaleCartProps = {
  provider: "",
  items: [],
};

const useCartActions = (initialCart = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialCart);

  const addItemHandler = (item: WholesaleProduct, quantity = 1) => {
    if (state.provider == "" || state.provider == item.wholesalerType) {
      dispatch({ type: 'ADD_ITEM', payload: { ...item, quantity } });
      dispatch({ type: 'SET_PROVIDER', payload: item.wholesalerType });
    }
  };

  const removeItemHandler = (item: WholesaleProduct, quantity = 1) => {
    dispatch({ type: 'REMOVE_ITEM', payload: { ...item, quantity } });
  };

  const clearItemFromCartHandler = item => {
    dispatch({ type: 'CLEAR_ITEM_FROM_CART', payload: item });
    dispatch({ type: 'SET_PROVIDER', payload: '' });
  };

  const setSubstitutePreferenceHandler = (uuid: string, preference: boolean) => {
    dispatch({ type: 'SET_SUBSTITUTE_PREFERENCE', payload: { uuid, preference } });
  };

  const clearCartHandler = () => dispatch({ type: 'CLEAR_CART' });

  const rehydrateLocalState = (payload) => {
    dispatch({ type: 'REHYDRATE', payload });
  };

  const isInCartHandler = (data) => {
    return state.items?.some((item) => {
      return item?.uuid === data?.uuid;
    });
  };

  const getItemHandler = (id) => {
    return state.items?.find((item) => item.upc === id);
  };

  const getItemByMidasHandler = (midas) => {
    return state.items?.find((item) => item.wholesalerProductCode === midas);
  }

  const getItemByUuidHandler =(uuid) => {
    return state.items?.find((item) => item.uuid === uuid);
  }

  const getCartItemsPrice = () => cartItemsTotalPrice(state.items);
  
  const getItemsCount = state.items?.reduce((acc, item) => acc + item.quantity, 0);

  const getProductsUpcsHandler = () => {
    return arrayUniq(state.items.map(x => x.upc));
  }

  return {
    state,
    getItemsCount,
    rehydrateLocalState,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    setSubstitutePreferenceHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    getCartItemsPrice,
    getProductsUpcsHandler,
    getItemByMidasHandler,
    getItemByUuidHandler,
  };
};

export const WholesaleCartProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    getItemsCount,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    setSubstitutePreferenceHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    getCartItemsPrice,
    getProductsUpcsHandler,
    getItemByMidasHandler,
    getItemByUuidHandler,
  } = useCartActions();

  const { rehydrated, error } = useStorage(state, rehydrateLocalState);

  return (
    <WholesaleCartContext.Provider
      value={{
        items: state.items,
        itemsCount: getItemsCount,
        provider: getWholesalerById(state.provider),
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        removeItemFromCart: clearItemFromCartHandler,
        setSubstitutePreference: setSubstitutePreferenceHandler,
        clearCart: clearCartHandler,
        isInCart: isInCartHandler,
        getItem: getItemHandler,
        calculateSubtotal: getCartItemsPrice,
        getProductUpcs: getProductsUpcsHandler,
        getItemByMidas: getItemByMidasHandler,
        getItemByUuid: getItemByUuidHandler,
      }}
    >{children}</WholesaleCartContext.Provider>
  );
};

export const useWholesaleCart = () => useContext(WholesaleCartContext);

export default useWholesaleCart;
