import { TimeFrameProps } from 'helper/datetime/useTimeFrame';
import { checkEnvironment } from 'helper/environment';
import { Shop } from 'models/Shop';

export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';

export const _PRODUCTS_ROUTE = '/products';
export const LIVE_PRODUCTS_ROUTE = _PRODUCTS_ROUTE + '/live';
export const ADD_PRODUCTS_ROUTE = _PRODUCTS_ROUTE + '/add';
export const DRAFT_PRODUCTS_ROUTE = _PRODUCTS_ROUTE + '/draft';

export const _REMOTE_REPORTS_ROUTE = '/reports';
export const STORE_REMOTE_REPORT_ROUTE = _REMOTE_REPORTS_ROUTE + '/store';
export const NO_CATEGORY_REMOTE_REPORT_ROUTE =
  _REMOTE_REPORTS_ROUTE + '/category/none';
export const PRODUCT_REMOTE_REPORT_ROUTE =
  _REMOTE_REPORTS_ROUTE + '/product/[upc]';
export const CATEGORY_REMOTE_REPORT_ROUTE =
  _REMOTE_REPORTS_ROUTE + '/category/[id]';

export const _ORDERS_ROUTE = '/orders';
export const LIVE_ORDERS_ROUTE = _ORDERS_ROUTE + '/live';
export const DELIVERED_ORDERS_ROUTE = _ORDERS_ROUTE + '/delivered';
export const PAST_ORDERS_ROUTE = _ORDERS_ROUTE + '/past';

export const PROMOTIONS_ROUTE = '/promotions';
export const PROMOTIONS_DETAIL_ROUTE = PROMOTIONS_ROUTE + '/[promotionId]';
export const NEW_PROMOTION_ROUTE = PROMOTIONS_ROUTE + '/new';

export const WHOLESALE_ROUTE = '/wholesale';
export const WHOLESALE_BOOKER_ROUTE = WHOLESALE_ROUTE + '/booker';
export const WHOLESALE_CHECKOUT_ROUTE = WHOLESALE_ROUTE + '/checkout';
export const WHOLESALE_ORDERS_ROUTE = WHOLESALE_ROUTE + '/orders';
export const WHOLESALE_ORDER_DETAIL_ROUTE =
  WHOLESALE_ROUTE + '/orders/[orderId]';

export const INVOICES_ROUTE = '/invoices';
export const INVOICE_PDF_ROUTE = '/invoice/pdf/[year]/[month]';

export const _POS_ROUTE = '/pos';
export const POS_SCAN_ROUTE = _POS_ROUTE + '/scan';
export const GENERATE_LABELS_ROUTE = _POS_ROUTE + '/labels/generate';
export const PREVIEW_LABELS_ROUTE = _POS_ROUTE + '/labels/preview';

export const POS_REPORTS_ROUTE = _POS_ROUTE + '/reports';
export const POS_REPORT_DETAIL_ROUTE =
  POS_REPORTS_ROUTE + '/[reportType]/[reportId]';

export const YOUR_STORE_ROUTE = '/your-store';
export const _IN_STORE_SETTINGS_ROUTE = YOUR_STORE_ROUTE + '/in-store';
export const _ONLINE_SETTINGS_ROUTE = YOUR_STORE_ROUTE + '/online';
export const _POS_SETTINGS_ROUTE = YOUR_STORE_ROUTE + '/pos';

export const SALES_ROUTE = _IN_STORE_SETTINGS_ROUTE + '/transactions';
export const SALES_DELETED_ROUTE = SALES_ROUTE + '/deleted';
export const SALES_DETAIL_ROUTE = SALES_ROUTE + '/[transactionId]';

export const OPENING_HOURS_ROUTE = _ONLINE_SETTINGS_ROUTE + '/hours';
export const ONLINE_DELIVERY_SERVICES_ROUTE =
  _ONLINE_SETTINGS_ROUTE + '/services';

export const SUPPLIERS_LIST_ROUTE = _IN_STORE_SETTINGS_ROUTE + '/suppliers';

export const POS_SERVICES_ROUTE = _POS_SETTINGS_ROUTE + '/services';
export const POS_PERMISSIONS_ROUTE = _POS_SETTINGS_ROUTE + '/permissions';
export const POS_PERMISSIONS_USER_EDIT_ROUTE =
  _POS_SETTINGS_ROUTE + '/permissions/user/[userId]';
export const POS_PERMISSIONS_USER_CREATE_ROUTE =
  _POS_SETTINGS_ROUTE + '/permissions/user/new';

export const generateDevoStoreLink = (shopState: Shop): string => {
  const domain = checkEnvironment('live') ? 'devo.co.uk' : 'stable.devo.co.uk';
  return `https://${domain}/shop/${shopState.prettyId}`;
};

const _formatDate = (date: Date) => Math.round(date.getTime() / 1000);
export const generateTimeFrameUrlQuery = (props: TimeFrameProps): string => {
  if (props.timeFrame === 'CUSTOM') {
    return `?from=${_formatDate(props.fromDate)}&to=${_formatDate(
      props.toDate
    )}`;
  } else {
    return `?period=${props.timeFrame.toLowerCase()}`;
  }
};
