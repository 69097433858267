import React from 'react'
import styled from 'styled-components'
import { Caption } from 'styles/Global';

import BookerImage from 'image/wholesale/booker.png'
import BestwayImage from 'image/wholesale/bestway.png'
import NisaImage from 'image/wholesale/nisa.png'

const imageForWholesaler = (wholesaler: string): any => {
  switch (wholesaler) {
    case "BOOKER": return BookerImage;
    case "BESTWAY": return BestwayImage;
    case "NISA": return NisaImage;
    default: return null;
  }
}

type WholesaleLogoProps = {
  wholesaler: string;
  as?: "img" | "div";
  width?: Value;
  height?: Value;
  marginTop?: Value;
  marginRight?: Value;
  marginBottom?: Value;
  marginLeft?: Value;
}

type Value = number | string;
const parseValue = (x: number | string): string => typeof(x) == "string" ? x : `${x}px`;

type ImageProps = { url: string; width: Value; height: Value; marginTop: Value; marginRight: Value; marginBottom: Value; marginLeft: Value; };
export const Image = styled.div<ImageProps>`
  width: ${props => parseValue(props.width)};
  height: ${props => parseValue(props.height)};
  margin-top: ${props => parseValue(props.marginTop)};
  margin-right: ${props => parseValue(props.marginRight)};
  margin-bottom: ${props => parseValue(props.marginBottom)};
  margin-left: ${props => parseValue(props.marginLeft)};
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;

const WholesaleLogo: React.FC<WholesaleLogoProps> = ({ 
  wholesaler,
  as = "div",
  height = 12,
  width = "100%",
  marginTop = 4,
  marginRight = "auto",
  marginBottom = 4,
  marginLeft = "auto",
}) => {

  let source = imageForWholesaler(wholesaler);
  let description = wholesaler.charAt(0).toUpperCase() + wholesaler.slice(1).toLowerCase();
  let className = `noselect wholesale-logo ${wholesaler.toLowerCase()}`

  if (!source) {
    return (
      <div>
        <Caption className={className}>
          {description}
        </Caption>
      </div>
    )
  }

  if (as === "img") {
    return <img 
      src={source} 
      alt={description} 
      className={className} 
      style={{
        width, height,
        marginTop, marginRight, marginBottom, marginLeft
      }}
    />
  } else {
    return <Image url={source} {...{ className, width, height, marginTop, marginRight, marginBottom, marginLeft }} />
  }
}

export default WholesaleLogo;