import { addDaysToDate, addMonthsToDate, addSecondsToDate, getEndOfDay, getStartOfDay } from 'helper/datetime/dates'
import { faCalendar, faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons'
import { faCalendar as fasCalendar, faCalendarAlt as fasCalendarAlt, faCalendarDay, faCalendarWeek as fasCalendarWeek } from '@fortawesome/free-solid-svg-icons'
import { DateGroupKey } from 'helper/products/reports'

export const DEFAULT_TIME_FRAME = "TODAY";

export type TimeFrame =
  "TODAY" |
  "YESTERDAY" |
  "LAST_7_DAYS" |
  "LAST_14_DAYS" |
  "LAST_30_DAYS" |
  "LAST_6_MONTHS" |
  "LAST_12_MONTHS" |
  "CUSTOM";

export const TIME_FRAME_ENUM = [
  "TODAY",
  "YESTERDAY",
  "LAST_7_DAYS",
  "LAST_14_DAYS",
  "LAST_30_DAYS",
  "LAST_6_MONTHS",
  "LAST_12_MONTHS",
  "CUSTOM",
]

export const TIME_FRAME_ENUM_NO_CUSTOM = [
  "TODAY",
  "YESTERDAY",
  "LAST_7_DAYS",
  "LAST_14_DAYS",
  "LAST_30_DAYS",
  "LAST_6_MONTHS",
  "LAST_12_MONTHS",
]

export const TIME_FRAME_LABELS = {
  "TODAY": "Today",
  "YESTERDAY": "Yesterday",
  "LAST_7_DAYS": "Last 7 days",
  "LAST_14_DAYS": "Last 14 days",
  "LAST_30_DAYS": "Last 30 days",
  "LAST_6_MONTHS": "Last 6 months",
  "LAST_12_MONTHS": "Last 12 months",
  "CUSTOM": "Custom",
}

export const PREVIOUS_TIME_FRAME_LABELS = {
  "TODAY": "Yesterday",
  "YESTERDAY": "Day before yesterday",
  "LAST_7_DAYS": "Previous 7 days",
  "LAST_14_DAYS": "Previous 14 days",
  "LAST_30_DAYS": "Previous 30 days",
  "LAST_6_MONTHS": "Previous 6 months",
  "LAST_12_MONTHS": "Previous 12 months",
  "CUSTOM": "",
}

export const TIME_FRAME_DESCRIPTIONS = {
  "TODAY": "today",
  "YESTERDAY": "yesterday",
  "LAST_7_DAYS": "over the last 7 days",
  "LAST_14_DAYS": "over the last 14 days",
  "LAST_30_DAYS": "over the last 30 days",
  "LAST_6_MONTHS": "over the last 6 months",
  "LAST_12_MONTHS": "over the last 12 months",
  "CUSTOM": "",
}
export const getTimeFrameDescription = (x: TimeFrame) => TIME_FRAME_DESCRIPTIONS[x] || "";

export const TIME_FRAME_ICONS = {
  "TODAY": faClock,
  "YESTERDAY": faCalendarDay,
  "LAST_7_DAYS": fasCalendarWeek,
  "LAST_14_DAYS": fasCalendarWeek,
  "LAST_30_DAYS": faCalendarAlt,
  "LAST_6_MONTHS": fasCalendarAlt,
  "LAST_12_MONTHS": faCalendar,
  "CUSTOM": fasCalendar,
}

export const TIME_FRAME_ICON_COLORS = {
  "TODAY": "var(--primary-label-color)",
  "YESTERDAY": "var(--primary-label-color)",
  "LAST_7_DAYS": "var(--primary-label-color)",
  "LAST_14_DAYS": "var(--primary-label-color)",
  "LAST_30_DAYS": "var(--primary-label-color)",
  "LAST_6_MONTHS": "var(--primary-label-color)",
  "LAST_12_MONTHS": "var(--primary-label-color)",
  "CUSTOM": "var(--secondary-label-color)",
}

const _SUGGESTED_GROUPING_FOR_TIME_FRAME = {
  "TODAY": "TIME",
  "YESTERDAY": "TIME",
  "LAST_7_DAYS": "DAY",
  "LAST_14_DAYS": "DAY",
  "LAST_30_DAYS": "DAY",
  "LAST_6_MONTHS": "MONTH",
  "LAST_12_MONTHS": "MONTH",
  "CUSTOM": "DAY",
}
export const getInitialGroupingForTimeFrame = (timeFrame: TimeFrame): DateGroupKey => {
  // @ts-ignore
  return _SUGGESTED_GROUPING_FOR_TIME_FRAME[timeFrame];
}

export const getTimeFrameDates = (timeFrame: TimeFrame): Date[] => {
  let d1 = new Date(); let d2 = new Date();
  switch (timeFrame) {
    case "TODAY":
      return [getStartOfDay(d1), getEndOfDay(d2)];
    case "YESTERDAY":
      return [getStartOfDay(addDaysToDate(d1, -1)), getEndOfDay(addDaysToDate(d2, -1))];
    case "LAST_7_DAYS":
      return [getStartOfDay(addDaysToDate(d1, -6)), getEndOfDay(d2)];
    case "LAST_14_DAYS":
      return [getStartOfDay(addDaysToDate(d1, -13)), getEndOfDay(d2)];
    case "LAST_30_DAYS":
      return [getStartOfDay(addDaysToDate(d1, -29)), getEndOfDay(d2)];
    case "LAST_6_MONTHS":
      return [getStartOfDay(addMonthsToDate(d1, -6)), getEndOfDay(d2)];
    case "LAST_12_MONTHS":
      return [getStartOfDay(addMonthsToDate(d1, -12)), getEndOfDay(d2)];
    default:
      return [d1, d2];
  }
}

export const getPreviousTimeFrameDates = (timeFrame: TimeFrame, _d1: Date = null, _d2: Date = null): Date[] => {
  let d1 = _d1 || new Date(); let d2 = _d2 || new Date();
  switch (timeFrame) {
    case "TODAY":
      return [getStartOfDay(addDaysToDate(d1, -1)), getEndOfDay(addDaysToDate(d2, -1))];
    case "YESTERDAY":
      return [getStartOfDay(addDaysToDate(d1, -2)), getEndOfDay(addDaysToDate(d2, -2))];
    case "LAST_7_DAYS":
      return [getStartOfDay(addDaysToDate(d1, -15)), getEndOfDay(addDaysToDate(d2, -8))];
    case "LAST_14_DAYS":
      return [getStartOfDay(addDaysToDate(d1, -29)), getEndOfDay(addDaysToDate(d2, -15))];
    case "LAST_30_DAYS":
      return [getStartOfDay(addDaysToDate(d1, -61)), getEndOfDay(addDaysToDate(d2, -31))];
    case "LAST_6_MONTHS":
      return [getStartOfDay(addMonthsToDate(d1, -13)), getEndOfDay(addMonthsToDate(d2, -7))];
    case "LAST_12_MONTHS":
      return [getStartOfDay(addMonthsToDate(d1, -25)), getEndOfDay(addMonthsToDate(d2, -13))];
    case "CUSTOM":
      if (_d1 && _d2) {
        let difference = Math.round((d2.getTime() - d1.getTime()) / 1000);
        return [getStartOfDay(addSecondsToDate(d1, -difference)), d1];
      }
    default:
      return [d1, d2];
  }
}
