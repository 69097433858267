import styled from 'styled-components'
import { Button, IconButton, Module } from 'styles/Global'

export const Header = styled.div`
  h1 {
    margin: 48px 24px 12px;
  }
`;

export const Wholesalers = {
  Grid: styled.div`
    padding: 10px;
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  `,
  Link: styled.a`
    display: block;
    width: calc(100% / 3);
    margin: 10px;
    
    &:hover {
      text-decoration: none;
      & > div { box-shadow: var(--devo-heavy-shadow); }
    }

    @media screen and (max-width: 767px) {
      width: auto;
    }
  `,
  Container: styled.div`
    display: block;
    width: calc(100% / 3);
    margin: 10px;
    @media screen and (max-width: 767px) {
      width: auto;
    }
  `,
  Item: styled(Module)`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    padding: 28px 20px;
    text-align: center;
  `,
  Customer: styled.button`
    margin: 4px auto;
    padding: 4px 16px;
    text-align: center;
    background-color: transparent;
    text-decoration: none;
    border: none;
    width: fit-content;
    border-radius: 999px;
    font-size: 14px;
    color: var(--secondary-label-color);
    transition: background-color 0.1s;
    
    &:hover {
      background-color: var(--secondary-background-color);
    }
  `,
  Button: styled(IconButton)`
    width: max-content;
    margin: 16px auto 0;
    padding: 8px 16px;
    border-radius: 999px;
    &:hover { transform: none; }
  `,
}